import React from 'react'
import './introduce.css'
import { useNavigate } from 'react-router-dom';

const Introduce = () => {
    const navigate = useNavigate();
    const startQuiz = () => {
        navigate("/quiz");
    }

    return (
        <div className='introduce'>
            <div className="introduce-container">
            <img width="220px" src="" alt=""/>
                <div className="quiz-info">
                    Question App'e hoşgeldiniz! 10 adet zor soru ile bilgi seviyenizi ölçün. Her soru için 30 saniye süreniz bulunmaktadır.

                </div>
                <div id="start" onClick={startQuiz} className='introduce-btn'>Teste başla</div>
            </div>
        </div>
    )
}

export default Introduce
